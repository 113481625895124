(function($){
  $(function(){

    $('ul.quick-category > li > a').click(function(e){
     
      var cat = $(this).attr('href').split('#')[1];
      
      if(cat === 'category_hot-deals') {
         e.preventDefault();
        location.href = location.origin + '/products/hot-deals';
      }
    });
  
     $('#category1 ul.pagination > li > a').click(function(e){
        e.preventDefault();
        var href = $(this).attr('href');
        window.location = window.location.origin + window.location.pathname + href;
      });

    $('.button-collapse,.button-collapse1').sideNav();
    $('.materialboxed').materialbox();

    $('.modal,.modal-trigger').modal();
    $url = window.location.href;
    if($url.indexOf('order-review') > -1 && $url.indexOf('?review=submitted') === -1) {
      $('#review-and-rating').modal('open');
    }

     $( '.swipebox' ).swipebox();
     $('.carousel.carousel-slider').carousel({fullWidth: true});


    var previousScroll = 0;

    // $(window).scroll(function(){
    //    var currentScroll = $(this).scrollTop();
    //    if (currentScroll > previousScroll){
    //       $('.footer-nav').slideUp();
    //    } else {
    //       $('.footer-nav').slideDown();
          
    //    }
    //    previousScroll = currentScroll;
    // });

    $('.datepicker').pickadate({
      selectMonths: true, // Creates a dropdown to control month
      selectYears: 15, // Creates a dropdown of 15 years to control year,
      today: 'Today',
      clear: 'Clear',
      format: 'dd-mm-yyyy',
      close: 'Ok',
      closeOnSelect: false // Close upon selecting a date,
    });

    

    $('.search-wrapper i.left').click(function(){
      $('#search-modal').modal('close');
    });
     
    $('#search').click(function(){
      $('#search-modal').modal('open');
      $("#txt_search").focus();
    });

      var productId,maxAllowed,isSubstract;

   $('.trigger-qty-modal').click(function(){
      
      productId = $(this).attr('data-product-id');
      maxAllowed = $(this).attr('data-max');
      isSubstract= $(this).attr('data-substract');

      $('[name="productId"]').val(productId);
      $('[name="maxAllowed"]').val(maxAllowed);
      $('[name="isSubstract"]').val(isSubstract);

      // qty = $('#qty-'+$(this).attr('data-sno')).val();
      // $("[name='qty-id']").val($(this).attr('data-sno'));
      // $('span.qty').removeClass('active');
      // $("[data-update='"+qty+"']").addClass('active');
      $('#update-qty').modal('open');
   });

    $('span.qty').click(function(e){

     
    isSubstract = parseInt($('[name="isSubstract"]').val());
    var max= parseInt($('[name="maxAllowed"]').val());
    var qty = parseInt($(this).attr('data-update'));
     if(max > 5 && isSubstract == 1) { max = 5 };
     if(isSubstract == 0) { max = 5 };
    var product_id =  $('[name="productId"]').val();
    if (qty >= 1 && qty <= max) {
        update_cart_qty(product_id, qty);
    } else {
        fun_error_message("Maximum allowed (or) available  quantity is " + max);
        e.preventDefault();
        return false;
        
    }
}); 

   
   // $('span.qty').click(function(){
   //    fun_show_loader();
   //    $('#qty-'+$("[name='qty-id']").val()).val($(this).attr('data-update'));
   //    price = $('#price-'+$("[name='qty-id']").val()).val();
   //    qty = parseInt($(this).attr('data-update'));
   //    total = price*qty;
   //    $('#price-total-'+$("[name='qty-id']").val()).val(total);
   //    grand_total = 0;
   //    $('input.price-total').each(function(){
   //      grand_total = grand_total + parseInt($(this).val());
   //    });
     
   //    $('#update-qty').modal('close');

   //    setTimeout(function(){
   //       $('.grand_total > .amt').each(function(){
   //        $(this).text(grand_total);
   //    });
   //      fun_hide_loader();
   //    },500);
   // });


  
   
  //  $(window).scroll(function(){
  //   if ($(window).scrollTop() >= 100) {
  //      $('nav.fix').addClass('fixed').slideDown();
  //   }
  //   else {
  //      $('nav.fix').removeClass('fixed');
  //   }
  // });

 $('.body-wrapper').scroll(function(){
    // console.log($(this).scrollTop());
    if ($(this).scrollTop() >= 100) {
       $('nav.fix').addClass('fixed');
    }
    else {
       $('nav.fix').removeClass('fixed');
    }
  });
    

   $('.alert button.close').click(function(){
      $(this).parents('.alert').remove();
   });

    $('span.review').click(function(){
      $('#'+$(this).attr('for')).trigger('click');
    });


    $('#formAddCustomerReview').submit(function(e){
      e.preventDefault();
     
      if($('input[name="rating"]:checked').length === 0){
        fun_error_message('Please select your rating.');
        return true;
      }

      if(fun_get_value('txtReviewTitle') === '' || fun_get_value('txtReviewDescription') === ''){
          fun_error_message('Please enter required details.');
          return true;
      }

      fun_show_loader();

      $.ajax({  
        type: "POST",
        dataType: "JSON",
        url: baseURL+"ajax/customer-process/ADD-REVIEW",
        data:$(this).serialize()+'&_token='+fun_get_token(),
          success: function(response) {
            if(window.location.href.indexOf('order-review') > -1) {
                Materialize.Toast.removeAll();
                $(".loader").addClass("hide");
                Materialize.toast(response.message, 5000);

            } else{
                 fun_success_message(response.message);
            }
          
            setTimeout(function(){
              if(window.location.href.indexOf('?review=submitted') > -1 ){
                window.location.href=window.location.href;
              } else {
                window.location.href=window.location.href+'?review=submitted';
              }
              
            },500);
          },
         error: function() {
          fun_hide_loader();
          fun_error_message("Error occured please try after some time");
         }
      }); 

      
    });



    $('#formEditCustomerReview').submit(function(e){
      e.preventDefault();
      
      if($('input[name="rating"]:checked').length === 0){
        fun_error_message('Please select your rating.');
        return true;
      }

      if(fun_get_value('txtReviewTitle') === '' || fun_get_value('txtReviewDescription') === ''){
          fun_error_message('Please enter required details.');
          return true;
      }

      fun_show_loader();

      $.ajax({  
        type: "POST",
        dataType: "JSON",
        url: baseURL+"ajax/customer-process/EDIT-REVIEW",
        data:$(this).serialize()+'&_token='+fun_get_token(),
          success: function(response) {
            if(window.location.href.indexOf('order-review') > -1) {
                Materialize.Toast.removeAll();
                $(".loader").addClass("hide");
                Materialize.toast(response.message, 5000);

            } else{
                 fun_success_message(response.message);
            }
            setTimeout(function(){
              if(window.location.href.indexOf('?review=submitted') > -1 ){
                window.location.href=window.location.href;
              } else {
                window.location.href=window.location.href+'?review=submitted';
              }
            },500);
          },
         error: function() {
          fun_hide_loader();
          fun_error_message("Error occured please try after some time");
         }
      }); 

      
    });

    $('a.clear-pincode').click(function(){

      $.post(baseURL+'ajax/pincode-process/CLEAR',{'_token':fun_get_token()},function(r){
          if(r.status == true) location.reload();
          else fun_error_message("Error occured please try after some time");
      },'json');
   });


    if (typeof review !== 'undefined') {
       $('span[for="star'+review.rating+'"]').trigger('click');
       fun_set_value('txtReviewId',review.review_id);
       fun_set_value('txtReviewTitle',review.title);
       fun_set_value('txtReviewDescription',review.review);
    }
    
    
    if(fun_get_value_we("current_route")==='category'){
      $('.footer-nav div.category').addClass('active');
      var id = window.location.href.split('#');
      var $id = $('#'+id[1]);
      if ($id.length === 0) { return;  }
      var pos = $id.offset().top-120;
      setTimeout(function(){  $('body, html').animate({scrollTop: pos}); },500);
           
    }else{
      $('.footer-nav div.home').addClass('active');
    }

      

  }); 
    

})(jQuery); 




      var items = [];
      
      function winPos(win) {
          return { x: 0, y: 0, x2: win.width() - 1, y2: win.height() - 1 };
      }
     
      function pos(win, el) {
          var p = el.offset();
          var x = p.left - win.scrollLeft();
          var y = p.top - win.scrollTop();
          return { x: x, y: y, x2: x + el.width() -1, y2: y + el.height() - 1 };
      }
      
      function intersects(a, b) {
          return !(a.x2 < b.x || a.x > b.x2 || a.y2 < b.y || a.y > b.y2);
      }
      
      function check(win, w, item) {
          var p = pos(win, $(item.el));
          var s = intersects(w, p);
          if (s != item.shown) {
              item.shown = s;
              (s ? item.show : item.hide).call(item.el);
          }
          // console.log(p);console.log(s);
      }

     


  (function($){

     $.fn.visible = function(show, hide){
          var win = $(window), w = winPos(win);
          return this.each(function(i, el){
              var item = { el: el, show: show, hide: hide, shown: false };
              items.push(item);
              check(win, w, item);
          });
      };
      
     
      
      $.fn.lazyImage1 = function(){
          return this.visible(function(){
            if($(this).hasClass('lazy-img')){
                $(this).removeClass('lazy-img');
                $(this).attr('src',$(this).attr('data-src')).css('opacity', 0).load(function(){
                  $(this).animate({ opacity: 1 });
                });
              
            }
          }, function(){
             $(this).removeClass('lazy-img');
          });
      };

 $(window,'body','.body-wrapper').on('scroll resize', function(e){
         $('.lazy-img').lazyImage1();
      });

 // $('.body-wrapper').scroll(function(){ });

setTimeout(function() {  
  $('.lazy-img').lazyImage1();
},100);
  $('.lazy-img').lazyImage1();


  })(jQuery);



   
