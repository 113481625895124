var baseURL = '';
var preAddress = '';
var filterFeature = [];
var cancel_product_id = '';
var return_product_id = '';
var current_route = '';
var image_asset_url = '';
var image_set_flag = 0;
var device = '';
var slider;
var cart_id;

$(window).bind("load", function() {

   console.log("coming");
 fun_load_time_update();
});


 $(window).scroll(function() {
	     if ($('.col-md-3.sidebar.hide_on_mobile').height() > $(window).height() - 120) {
	        $('.col-md-3.sidebar.hide_on_mobile').css({'position':'sticky','top':($(window).height())-($('.col-md-3.sidebar.hide_on_mobile').height())});
	     } else {
	        $('.col-md-3.sidebar.hide_on_mobile').css({'position':'sticky','top':120});
	     }

    });

function get_zero_qty(variant) {
	return variant.qty == 0;
}

 function single_variants(){
     var single_variants = variants.filter(get_zero_qty);
        $.each(single_variants , function(i,k){
            $('input[value="'+k.config_ids+'"]').attr('disabled',true);;
            $('label[for="'+k.config_ids+'"]').addClass('disabled');
        });
}

function more_than_variants() {

        var item_id = location.search.split('&').
        filter(function(val) {return val.indexOf('lsvi') !=-1;});
        var variant_id = location.search.split('&')
        .filter(function(val) { return val.indexOf('lsvv') !=-1;});
        
        if(item_id.length > 0 && variant_id.length > 0 ) {

            item_id = item_id[0].split('=')[1];
            variant_id = variant_id[0].split('=')[1];
           
             var available_products = variants.
             filter(function(variant) {return (variant.config_ids).indexOf(item_id) != -1 && variant.qty > 0 ; });
             $('input[type="radio"]').attr('disabled',true);
             $('label.label').addClass('disabled');

             $('input.variants'+variant_id).removeAttr('disabled');
             $('label.variants'+variant_id).removeClass('disabled');
            $.each(available_products ,function(i,v){

                    $.each(v.config_ids.split(','),function(j,k){
                     $('input[value="'+k+'"]').removeAttr('disabled');
                     $('label[for="'+k+'"]').removeClass('disabled');
                     
                    });
                
            });
        }
       
}
function progress() {
  var elem = document.getElementById("myBar");   
  var width = 1;
  var id = setInterval(frame, 10);
  function frame() {
    if (width >= 100) {
      clearInterval(id);
      $(document.getElementById("myProgress")).remove();
    } else {
      width++; 
      elem.style.width = width + '%'; 
    }
  }
}


$(document).ready(function(){

	 if($('[name="variants_count"]').val() == 1) {
        single_variants();
       
    } else {

        more_than_variants();
    }

$('.variants label').click(function(){

    if(!$(this).hasClass('disabled') && ! $('#'+$(this).attr('for')).is(':checked')) {
        $('body').append('<div id="myProgress"><div id="myBar"></div><div class="blur"></div></div>');
        progress();
        var lsVi = $(this).attr('for');
        var lsV = $(this).attr('data-variant');
        setTimeout(function() {
            var selectedVariants = $('#product_variants').serializeArray()
            .filter(function(form){return form.name!='variants[]';})
            .filter(function(form){ return form.name != 'variants_count';})
            .map(function(config){return config.value;}).toString();
            
            var url = location.origin + location.pathname + '?variants=' + selectedVariants + '&lsvi=' + lsVi + '&lsvv=' + lsV;
            location.href = url;
        },100);
        
         
       
    }
   
});

	if(top.location != window.location) {
    	window.location = '/invalid-page';
	}
	var max_cod_order_value = parseInt($('#max_cod_order_value').val());

	if(parseInt($('[name="paymentAmount"]').val()) > max_cod_order_value) {
		$('.payment-percent').removeClass('hidden');
		$('.payment-method.cod > .payment-opt.cod').html('<p>COD option is not available for order value above <b>Rs '+max_cod_order_value+'</b></p>');
	} else {
		$('.payment-percent').remove();
	}
	

	$('ul.dropdown-menu.container').hover(function(){
		$(document).find('li.mega-menu > a').removeClass('hover');
		$(this).prev('a').addClass('hover');
	},function(){
	   $(document).find('li.mega-menu > a').removeClass('hover');
	});
	fun_set_base_url();
	current_route = fun_get_value_we("current_route");
	console.log(current_route);
	image_asset_url = fun_get_value_we('image_asset_url');
	var scroll_count = 0;
	
	// Ordinary functions
	var pop_session = fun_get_value('txt_pop_session');
	var customer_session_count = fun_get_value("txt_customer_session_count");
	var customer_show_popup_click = fun_get_value("txt_customer_show_popup_click");
	device = fun_get_value('txt_device_type')

	// console.log(customer_session_count%customer_show_popup_click);

	// $('div.category-product .row .col-sm-6').css('height',Math.max.apply(null, $("div.category-product .row .col-sm-6").map(function (){
 //          return $(this).height() ;
 //      }).get()));

	if(pop_session == 0){
		if(fun_get_value_we('txt_pop_session_url')!='')
			fun_open_modal("modal_offer_pop_up");
	}
	else {
		if(customer_show_popup_click != ''){
			if(customer_session_count%customer_show_popup_click == 0)
				if(fun_get_value_we('txt_pop_session_url')!='')
					fun_open_modal("modal_offer_pop_up");
		}
	}

	// fun_load_product_images();
	$(window).scroll(function(){
		
		if(scroll_count>1 && image_set_flag==0){
			fun_load_product_images();
			image_set_flag = 1;
		}
		scroll_count++;
		// console.log(scroll_count);
	});

	if(current_route == '/') {
		console.log("Home Page");
		 $('.banner-image').each(function(){
		      var image = $(this).attr('lsrc');
		      $(this).css("background-image","url("+image+")");
		   });
	}
	else if(current_route == 'account/my-addresses') {
		fun_get_all_addresses(fun_get_value('txt_customer_id'));
	}
	
	else if(current_route == 'mini-category/{url}') {
	   scroll_count = 0;
	   $('.banner-image').each(function(){
	      var image = baseURL+'public/'+$(this).attr('lsrc');
	      $(this).css("background-image","url("+image+")");
	   });

	   $(window).scroll(function(){
	   	scroll_count++;
		    if(scroll_count>1){
			   $(".product-image").each(function(){
			      var image = baseURL+'public/'+$(this).attr('lsrc');
			      $(this).attr("src",image);
			   });

			   $(".list-image").each(function(){
			      var image = baseURL+'public/'+$(this).attr('lsrc');
			      $(this).attr("src",image);
			   });
			}
	   });
	}
	else if(current_route == 'product/{id}') {
		$('.slider').slider({height:'260px'});
		var device = fun_get_value("txt_device_type");
		if(device == 'desktop') {
			new Drift(document.querySelector('.drift-demo-trigger'), {
			    paneContainer: document.querySelector('.drift-zoom-section'),
			    inlinePane: 900,
			    inlineOffsetY: -85,
			    zoomFactor: 2,
			    containInline: true,
			    hoverBoundingBox: true
			  });
		}
	}
	else if(current_route == 'products/{super}/{main}' || current_route == 'products/{super}/{main}/{sub}' || current_route == 'products/{super}') {
		var minPrice2 = parseInt(fun_get_value_we("txtMinPrice"));
		var maxPrice2 = parseInt(fun_get_value_we("txtMaxPrice"));

		//console.log(minPrice+maxPrice);
		var minSelectedPrice = parseInt(fun_get_value_we("txtSelectedMinPrice"));
		var maxSelectedPrice = parseInt(fun_get_value_we("txtSelectedMaxPrice"));

		minSelectedPrice = (minSelectedPrice == '' ? minPrice2 : minSelectedPrice);
		maxSelectedPrice = (maxSelectedPrice == '' ? maxPrice2 : maxSelectedPrice);

		$("#slider-range").slider({
		                 range: "min",
		                 min: minPrice2,
		                 max: maxPrice2,
		                 
		                 slide: function (event, ui) {
		                     $("#para").text(ui.value);
		                 },
		                 
		                 change: function (event, ui) {
		                     alert('Stopped at ' + ui.value);
		                 } 
		});

		if(device == 'phone') {
			slider = document.getElementById('price-slider');
	         noUiSlider.create(slider, {
	          start: [minPrice2, maxPrice2],
	          connect: true,
	          step: 1,
	          orientation: 'horizontal', 
	          range: {
	            'min': minPrice2,
	            'max': maxPrice2
	          },
	          format: wNumb({
	            decimals: true,
		        thousand: ',',
		        prefix: '&#8377;',
	          })
	         });

		}

		var filters = fun_get_value_we("txtFilterFeatures");

		filters = filters.split(",");

		$(filters).each(function(index,value){
		  //filter_data = value.split("|");
		  if(value!='')
		    array_insert_remove(value,filterFeature);
		});
	}
	else if(current_route == 'promotions/customer-specific/{id}') {
		window.location =baseURL+'my-cart';
	}
	else if(current_route == 'checkout') {
		var customerID = fun_get_value("txt_customer_id");
		var device_type = fun_get_value("txt_device_type");

	    if(customerID!='' && device_type != 'phone'){
	      $("#accordinSection1").addClass("collapsed");
	      $("#accordinSection1").removeAttr("data-toggle");
	      $("#accordinSection1").removeAttr("href");
	      $("#collapseOne").removeClass("in");

	      $("#accordinSection2").addClass("collapsed");
	      $("#accordinSection2").removeAttr("data-toggle");
	      $("#accordinSection2").removeAttr("href");

	      $("#accordinSection3").trigger("click");

	      fun_get_addresses(customerID);

	      var selectedAddress = fun_get_value("txtSelectedAddress");

	      if(selectedAddress == ''){
	        $("#accordinSection4").addClass("collapsed");
	        $("#accordinSection4").removeAttr("data-toggle");
	        $("#accordinSection4").removeAttr("href");
	      }
	      else {

	      }

	      if(fun_get_value('txt_selected_address') !='')
	    		fun_select_address(fun_get_value('txt_selected_address'));
	    }
	    else if(customerID!='' && device_type == 'phone'){
	    	fun_get_addresses(customerID);
	    	$(".div_shipping_section").trigger("click");

	    	if(fun_get_value('txt_selected_address') !='')
	    		fun_select_address(fun_get_value('txt_selected_address'));
	    }
	    else {
	              // remove according 

	        console.log("coming here");

	        $("#accordinSection3").addClass("collapsed");
	        $("#accordinSection3").removeAttr("data-toggle");
	        $("#accordinSection3").removeAttr("href");

	        $("#accordinSection4").addClass("collapsed");
	        $("#accordinSection4").removeAttr("data-toggle");
	        $("#accordinSection4").removeAttr("href");

	        // Goto Register section 
		    if(fun_get_value("guest_mobile_created") !='' && device == 'desktop')
		    	fun_trigger_click('accordinSection2');
		    else if(fun_get_value("guest_mobile_created") !='' && device == 'phone')
		    	$("#modalNewCustomer").modal("open");
	    }
	}
	else if(current_route == 'gift-continue') {
		// $("#txt_otp_login_redirect").
		fun_set_value("txt_otp_login_redirect",'');
	}
	else if(current_route == 'account/update-profile') {
		$('.datepicker').pickadate({
		    selectMonths: true, // Creates a dropdown to control month
		    selectYears: 100, // Creates a dropdown of 15 years to control year,
		    today: 'Today',
		    clear: 'Clear',
		    format: 'dd-mm-yyyy',
		    close: 'Ok',
		    closeOnSelect: false // Close upon selecting a date,
		  });

		 $('select').material_select();
	}
	else if(current_route == 'account') {
		if(device == 'phone')
			$('select').material_select();
	}




	if(fun_get_value_we('txt_success_data')!='')
		fun_success_message(fun_get_value_we('txt_success_data'));

	if(fun_get_value_we('txt_error_data')!='')
		fun_error_message(fun_get_value_we('txt_error_data'));

	$('.date-field').datepicker({
        autoclose: true,
        todayHighlight: true,
        format: 'dd-mm-yyyy'
    });

    $("#accordinSection4").click(function(event){
    	console.log("log");
    	event.preventDefault();
    });

    $("#form_customer_return_order").submit(function(event){
    	fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/order-process/RETURN-ORDER",
				 data:"id="+return_product_id+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							fun_close_modal("modal_return_order");
							// window.location = baseURL+'account/view-order/'+response.order.custom_order_id;
							$("#span_return_order").html("");
							$("#span_order_status").html("Order Status : Returned");
							fun_success_message(response.message);
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});	

    	event.preventDefault();
    });

    $('#enquireProductForm').submit(function(e){
    
    var form = $(this);

   
        $.post(baseURL + 'product_enquiry', form.serialize(), function() {}, 'json').done(function(res) {
            if (res.status === true) {
            
               fun_success_message(res.msg);
            }  else {
               fun_error_message(res.msg);
            }

            $('#enquireProductForm')[0].reset();

            
        }).fail(function(res) {
            fun_error_message('Error occured please try again');
        });

        return false;
    

});

    $("#form_customer_cancel_order").submit(function(event){

    	// console.log(cancel_product_id);

    	var reason = fun_get_value("txt_cancel_reason");

    	if(reason == '') {
    		fun_error_message("Please enter the reason for the cancellation");
    	}	
    	else if(reason.length <= 50) {
    		fun_error_message("Cancellation reason should be minimum 50 characters");
    	}
    	else {
    		fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/order-process/CANCEL-ORDER",
				 data:"id="+cancel_product_id+'&_token='+fun_get_token()+'&reason='+reason,
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							fun_close_modal("modal_cancel_order");
							// window.location = baseURL+'account/view-order/'+response.order.custom_order_id;
							$("#span_cancel_order").html("");
							$(".cancel_section").html("");
							$("#span_order_status").html("Order Status : Cancelled");
							$(".span_order_status").html("Order Status : Cancelled")
							fun_success_message(response.message);
							setTimeout(function(){
								location.reload(true);
							},500);
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});	
    	}

    	event.preventDefault();
    });

    $("#formCartReg").submit(function(event){

    	var email = fun_get_value_we("txtCheckoutEmail");
		var mobile = fun_get_value("txtCheckoutMobile");

		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		var stat = regex.test(email);

		if(mobile.length !=10 ){
			fun_error_message("Please enter the valid mobile number");	
		}
		else if(stat!=true)
		{
			fun_error_message("Please enter the valid email");
		}
		else {
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/customer-process/CART-GUEST-REGISTER",
				 data:"mobile="+mobile+'&email='+email+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							// console.log(cart_id);
							add_to_cart(cart_id);
							fun_close_modal('loginAndRegister');
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});	
		}

    	event.preventDefault();
    });

    $("#formCustomerRegGuest").submit(function(event){
		var name = fun_get_value("txtCheckoutFullName");
		var email = fun_get_value_we("txtCheckoutEmail");
		var mobile = fun_get_value("txtCheckoutMobile");
		var password = fun_get_value("txtCheckoutPassword");
		var type = fun_get_value("txtCheckoutUserType");
		var verification = fun_get_value("txtCheckoutVerification");

		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		var stat = regex.test(email);

		console.log(email+'-'+stat);
		

		if(name == '' || mobile=='' || password=='') {
			fun_error_message("Please enter all the details");
		}
		else if(mobile.length !=10 ){
			fun_error_message("Please enter the valid mobile number");	
		}
		else if(stat!=true)
		{
			fun_error_message("Please enter the valid email");
		}
		else if(verification=='') {
			fun_error_message("Please enter the verification code");
		}
		else if(password.length<6) {
			fun_error_message("Password should be minimum 6 characters");
		}
		else if(verification!='VERIFIED' && type == 'GUEST') {
			fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"customer-process/VERIFY-GUEST-LOGIN",
				 data:"mobile="+mobile+'&verification='+verification,
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							$("#divCheckoutVerificationCode").css("display","none");
							fun_set_value("txtCheckoutVerification","VERIFIED");
							
							$("#accordinSection1").addClass("collapsed");
							$("#accordinSection1").removeAttr("data-toggle");
							$("#accordinSection1").removeAttr("href");
							$("#collapseOne").removeClass("in");

							$("#accordinSection2").addClass("collapsed");
							$("#accordinSection2").removeAttr("data-toggle");
							$("#accordinSection2").removeAttr("href");

							$("#accordinSection3").trigger("click");

							fun_set_value("txtCustomerID",response.customerID);
							customerID = response.customerID;
							fun_get_addresses(response.customerID);

						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});	
		}
		else if(verification!='VERIFIED' && type == 'REGISTER') {
			fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/checkout-process/VERIFY-LOGIN",
				 data:"mobile="+mobile+'&verification='+verification+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							// $("#divCheckoutVerificationCode").css("display","none");
							// fun_set_value("txtCheckoutVerification","VERIFIED");
							
							// $("#accordinSection1").addClass("collapsed");
							// $("#accordinSection1").removeAttr("data-toggle");
							// $("#accordinSection1").removeAttr("href");
							// $("#collapseOne").removeClass("in");

							// $("#accordinSection2").addClass("collapsed");
							// $("#accordinSection2").removeAttr("data-toggle");
							// $("#accordinSection2").removeAttr("href");

							// $("#accordinSection3").trigger("click");

							// fun_set_value("txtCustomerID",response.customerID);
							// customerID = response.customerID;
							// fun_get_addresses(response.customerID);

							window.location = window.location.href;

						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});	
		}
		else if(verification == 'VERIFIED') {
			fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/checkout-process/CUSTOMER-"+type,
				 data:"name="+name+'&email='+email+'&mobile='+mobile+'&_token='+fun_get_token()+'&password='+password,
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							fun_success_message("OTP sent, Please check your mobile");
							$("#divCheckoutVerificationCode").css("display","block");
							fun_set_value("txtCheckoutVerification","");
							$("#butResetOTP").css("display","block");
							$(".read-only").attr("disabled","disabled");
							$("#txt_encrypted_id").val(response.encrypted_id);
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});	
		}
		event.preventDefault();
	});

    $("#form_search").submit(function(event){
    	var search = fun_get_value('txt_search');
    	var url = fun_get_value_we("txt_search_url");
    	console.log(url);
    	if(url != '')
    		window.location = baseURL+'products/'+url;
    	else if(search!='')
    		window.location=baseURL+"search?key="+search;
    	event.preventDefault();
    });

	$("#formAddCustomerAddress").submit(function(event){
		var caption = fun_get_value("txtAddressCaption");
		var line1 = fun_get_value("txtAddressLine1");
		var line2 = fun_get_value("txtAddressLine2");
		var city = fun_get_value("txtCity");
		var state = fun_get_value("txtState");
		var pincode = fun_get_value("txtPincode");
		var mobile = fun_get_value("txtMobileNumber");
		// var mobile = '';

		if(caption == '' || line1 == '' || city == '' || state == '' || pincode == '' ) {
			fun_error_message("Please enter all the details");
		}
		// else if(mobile.length < 10 ) {
		// 	fun_error_message("Please enter the valid mobile number");
		// }
		else {
			fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/checkout-process/ADD-ADDRESS",
				data:"caption="+caption+'&line1='+line1+'&line2='+line2+'&city='+city+'&state='+state+'&pincode='+pincode+'&mobile='+mobile+'&customerID='+fun_get_value('txt_customer_id')+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							fun_success_message(response.message);
							fun_set_value("txt_address_added",'yes');
							fun_set_address_div(response.address);
							fun_close_modal("modalAddCustomerAddress");
							$('#formAddCustomerAddress')[0].reset();
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});		
		}
		event.preventDefault();
	});

	$("#formAddCustomerAddressAccount").submit(function(event){
		var caption = fun_get_value("txtAddressCaption");
		var line1 = fun_get_value("txtAddressLine1");
		var line2 = fun_get_value("txtAddressLine2");
		var city = fun_get_value("txtCity");
		var state = fun_get_value("txtState");
		var pincode = fun_get_value("txtPincode");
		var mobile = fun_get_value("txtMobileNumber");
		// var mobile = '';

		if(caption == '' || line1 == '' || city == '' || state == '' || pincode == '' ) {
			fun_error_message("Please enter all the details");
		}
		// else if(mobile.length < 10 ) {
		// 	fun_error_message("Please enter the valid mobile number");
		// }
		else {
			fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/checkout-process/ADD-ADDRESS",
				data:"caption="+caption+'&line1='+line1+'&line2='+line2+'&city='+city+'&state='+state+'&pincode='+pincode+'&mobile='+mobile+'&customerID='+fun_get_value('txt_customer_id')+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							fun_success_message(response.message);
							fun_set_address_div_account_page(response.address);
							fun_close_modal("modalAddCustomerAddress");
							$('#formAddCustomerAddressAccount')[0].reset();
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});		
		}
		event.preventDefault();
	});

	$("#formEditCustomerAddress").submit(function(event){
		var caption = fun_get_value("txtEditAddressCaption");
		var line1 = fun_get_value("txtEditAddressLine1");
		var line2 = fun_get_value("txtEditAddressLine2");
		var city = fun_get_value("txtEditCity");
		var state = fun_get_value("txtEditState");
		var pincode = fun_get_value("txtEditPincode");
		var mobile = fun_get_value("txtEditMobileNumber");
		// var mobile = '';
		var customerID = fun_get_value("txt_customer_id");

		if(caption == '' || line1 == '' || city == '' || state == '' || pincode == '' ) {
			fun_error_message("Please enter all the details");
		}
		// else if(mobile.length < 10 ) {
		// 	fun_error_message("Please enter the valid mobile number");
		// }
		else {
			fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/address-process/EDIT",
				data:"caption="+caption+'&line1='+line1+'&line2='+line2+'&city='+city+'&state='+state+'&pincode='+pincode+'&mobile='+mobile+'&customerID='+customerID+'&addressID='+addressID+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							fun_success_message(response.message);
							fun_set_address_div(response.address);
							fun_close_modal("modalEditCustomerAddress");
							$('#formEditCustomerAddress')[0].reset();
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});		
		}
		event.preventDefault();
	});

	$("#formEditCustomerAddressAccount").submit(function(event){
			var caption = fun_get_value("txtEditAddressCaption");
			var line1 = fun_get_value("txtEditAddressLine1");
			var line2 = fun_get_value("txtEditAddressLine2");
			var city = fun_get_value("txtEditCity");
			var state = fun_get_value("txtEditState");
			var pincode = fun_get_value("txtEditPincode");
			var mobile = fun_get_value("txtEditMobileNumber");
			// var mobile = '';
			var customerID = fun_get_value("txt_customer_id");

			if(caption == '' || line1 == '' || city == '' || state == '' || pincode == '' ) {
				fun_error_message("Please enter all the details");
			}
			// else if(mobile.length < 10 ) {
			// 	fun_error_message("Please enter the valid mobile number");
			// }
			else {
				fun_show_loader();
				$.ajax({								
					type: "POST",
					dataType: "JSON",
					url: baseURL+"ajax/address-process/EDIT",
					data:"caption="+caption+'&line1='+line1+'&line2='+line2+'&city='+city+'&state='+state+'&pincode='+pincode+'&mobile='+mobile+'&customerID='+customerID+'&addressID='+addressID+'&_token='+fun_get_token(),
						success: function(response) {
							fun_hide_loader();
							if(response.status==true) {
								fun_success_message(response.message);
								fun_set_address_div_account_page(response.address);
								fun_close_modal("modalEditCustomerAddress");
								$('#formEditCustomerAddressAccount')[0].reset()
							}
							else {
								fun_error_message(response.message);
							}
						},
					error: function() {
						fun_error_message("Error occured please try after some time");
					}
				});		
			}
			event.preventDefault();
		});

	$('#txt_search').autoComplete({
        onSelect: function(event, term, item) {
        	// fun_set_value("txt_product_auto_complete","");
         //    product_append(item.data('id'),item.data('name'));
         // fun_set_value('txt_search',item.data('name'));

         $("#txt_search").text(item.data('name'));
         $("#txt_search_url").text(item.data('url'));

         console.log(item.data('type'));
         if(item.data('type') == 'product'){
         	console.log("product");
         	// $("#txt_search").text(item.data('name'));
         	fun_set_value("txt_search",item.data('name'));
         	window.location = baseURL+'product/'+item.data('url');
         	// $("#form_search").submit();
         }
         else if(item.data('type') == 'category'){
         	// console.log("category");
         	// item.data('url');
         	// fun_set_value("txt_search",item.data('name'));
         	fun_set_value("txt_search_url",item.data('url'));
         	var sk = item.data('name');
         	$.get(baseURL+'ajax/get-cat-seo-url',{id:item.data('id')}, function(res){
         		window.location = baseURL+'products/'+res.seo_url + '?key=' + sk;
         	},'json');
         	// window.location = baseURL+'products/'+item.data('url');
         }
         
        },
        source: function(term, response){
            $.getJSON(baseURL+'ajax/product-auto-complete', { q: term }, 
            	function(data){ response(data); });
        },
        renderItem: function (item, search){
        	if(typeof(item['uv']) === "undefined"){ return false;}
	        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	        var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
	        return '<div class="autocomplete-suggestion" data-id="'+item['ci']+'" data-name="'+item['sk']+'" data-type="'+item['type']+'" data-url="'+item['ci']+'">'+item['uv']+'</div>';
    	}
    });


    $("#form_pop_up_offer").submit(function(event){
    	var name = fun_get_value("txt_offer_name");
		var mobile = fun_get_value("txt_offer_mobile");
		if(name == '')
			fun_error_message("Please enter the name");
		else if(mobile == '') 
			fun_error_message("Please enter the mobile number");
		else if(mobile.length != 10) 
			fun_error_message("Please enter the valid mobile number");
		else {
				fun_show_loader();
				$.ajax({								
					type: "POST",
					dataType: "JSON",
					url: baseURL+"ajax/coupon-process/DEFAULT-OFFER-COUPON",
					data:"mobile="+mobile+'&name='+name+'&_token='+fun_get_token(),
						success: function(response) {
							fun_hide_loader();
							if(response.status==true) {
								fun_success_message(response.message);
								fun_set_value("txt_offer_name","");
								fun_set_value("txt_offer_mobile","");
							}
							else {
								fun_error_message(response.message);
							}
						},
					error: function() {
						fun_error_message("Error occured please try after some time");
					}
				});		
			}
    	event.preventDefault();
    });

    $("#form_gift_card").submit(function(event){
    	var to = fun_get_value("txt_gift_to");
    	var mobile = fun_get_value("txt_gift_mobile");
    	var from = fun_get_value("txt_gift_from");
    	var message = fun_get_value("txt_gift_message");
    	var amount = fun_get_value("txt_gift_price");
    	var default_amount = fun_get_value("txt_selected_gift_price");

    	if(to=='' || mobile =='' || from ==''){
    		fun_error_message("Please enter the required fields");
    	}
    	// else if(amount!='' && amount<10) {
    	// 	fun_error_message("Amount should be greater than Rs.10");
    	// }
    	else {
    		fun_show_loader();
			$.ajax({								
				type: "POST",
				dataType: "JSON",
				url: baseURL+"ajax/gift-process/ADD",
				data:'from='+from+'&email='+to+'&message='+message+'&mobile='+mobile+'&amount='+amount+'&default_amount='+default_amount+'&_token='+fun_get_token(),
					success: function(response) {
						fun_hide_loader();
						if(response.status==true) {
							// fun_success_message(response.message);
							window.location = baseURL+'gift-continue/'+response.encrypted_id;
						}
						else {
							fun_error_message(response.message);
						}
					},
				error: function() {
					fun_error_message("Error occured please try after some time");
				}
			});		
    	}

    	event.preventDefault();
    });


    $("#form_register_new").submit(function(event){
    	var mobile = fun_get_value("txt_reg_mobile_new");
    	var otp = fun_get_value("txt_reg_otp_new");
    	var enc_id = fun_get_value("txt_customer_encrypted_id_new");

    	if(current_route == 'login-register')
			fun_set_value("txt_otp_login_redirect",baseURL+'account');

    	if(enc_id == '' ) {
    		if(mobile == ''){
	    		fun_error_message("Please enter the mobile number");
	    	}
	    	else if(mobile.length != 10) {
	    		fun_error_message("Please enter the valid mobile number");
	    	}
	    	else {
	    		fun_show_loader();
				$.ajax({								
					type: "POST",
					dataType: "JSON",
					url: baseURL+"ajax/customer-process/CUSTOMER-REG-NEW",
					 data:"mobile="+mobile+'&_token='+fun_get_token(),
						success: function(response) {
							fun_hide_loader();
							if(response.status==true) {
								fun_set_value("txt_customer_encrypted_id_new",response.enc_id);
								$("#txt_reg_mobile_new").attr("disabled","disabled");
								$("#div_opt_section_new").removeClass("hidden");

								// fun_close_modal("modal_return_order");
								fun_success_message(response.message);
							}
							else {
								fun_error_message(response.message);
							}
						},
					error: function() {
						fun_error_message("Error occured please try after some time");
					}
				});
	    	}
    	}
    	else {
    		if(otp == ''){
	    		fun_error_message("Please enter the otp");
	    	}
	    	else {
	    		fun_show_loader();
				$.ajax({								
					type: "POST",
					dataType: "JSON",
					url: baseURL+"ajax/customer-process/VERIFY-REG-NEW",
					 data:"otp="+otp+'&enc_id='+enc_id+'&_token='+fun_get_token(),
						success: function(response) {
							fun_hide_loader();
							if(response.status==true) {
								// window.location = window.location.href;
								fun_set_value("txt_otp_login_mobile",response.mobile);
								fun_set_value("txt_otp_login_otp",response.otp);
								fun_set_value("txt_otp_login_redirect",fun_get_value_we("txt_reg_otp_redirect"));
								// console.log(fun_get_value_we("txt_reg_otp_redirect"));
								$("#form_otp_login_new").submit();
								// fun_success_message(response.message);
							}
							else {
								fun_error_message(response.message);
							}
						},
					error: function() {
						fun_error_message("Error occured please try after some time");
					}
				});
	    	}	
    	}

    	event.preventDefault();
    });


});

// Function Start //

function fun_set_base_url()
{
	var full_url = window.location.href;
	device = fun_get_value("txt_device_type");
	full_url = full_url.replace("http://","");
	full_url = full_url.split("/");
	if(fun_get_value_we("base_url")=='')
		baseURL = "http://"+full_url[0]+'/'+full_url[1]+'/';
	else 
		baseURL = fun_get_value_we("base_url");
	// console.log(baseURL);
	return baseURL;
}

function fun_open_modal(id,arg)
{
	var device = fun_get_value('txt_device_type');
	if(device == 'phone') {
		if(!$('html').hasClass('swipebox-touch')) {
			$("#" + id).modal("open");
		}
		
	}
	else {
		if(arg == true){
			$('#'+id).modal({backdrop: 'static', keyboard: false});
		}
		$("#" + id).modal("show");
	}
}

function fun_close_modal(id)
{
	var device = fun_get_value('txt_device_type');
	if(device == 'phone')
		$("#" + id).modal("close");
	else 
	$("#"+id).modal("hide");
}

function fun_set_value(id,value)
{
	$("#"+id).val(value);
}

function fun_get_value(id)
{
	return encodeURIComponent($.trim($("#"+id).val()));
}

function fun_get_value_we(id)
{
	return $.trim($("#"+id).val());
}

function fun_get_page_name()
{
    var pageURL = $(location).attr("href");
    pageURL = pageURL.replace("http://", "");
    pageURL = pageURL.replace("https://", "");
    page = pageURL.split("/");
    return page[page.length - 1];
}

function fun_show_loader()
{
	if(device == 'phone' || (window.location.href.indexOf('order-review') > -1)) {
		$(".loader").removeClass("hide");
	}
	else {
		$('body').addClass('loading').addClass('overlay').loader('show', {
		    loader: false,
			overlay: true
		});
		return true;
	}
}

function fun_hide_loader()
{
	if(device == 'phone') {
		$(".loader").addClass("hide");
	}
	else {
		$('body').removeClass('loading').loader('hide');
		return true;
	}
}

function fun_modal_clear_msg()
{
	$(".errorSpan").html('');
	$(".successSpan").html('');
	$(".modal-footer .i").css("display","none");
}

function fun_show_modal_loader()
{
	$(".modal-footer i").css("display","block");
}

function fun_hide_modal_loader()
{
	$(".modal-footer i").css("display","none");
	fun_modal_clear_msg();
}

// DATE
function fun_date_to_frontend(date)
{
    date = date.split("-");
    return date[2] + '-' + date[1] + '-' + date[0];
}

function fun_date_to_backend(date)
{
    date = date.split("-");
    return date[2] + '-' + date[1] + '-' + date[0];
}

function fun_date_alone(datetime)
{
	datetime = datetime.split(" ");
	return datetime[0];
}

function fun_get_time_alone(datetime)
{
	datetime = datetime.split(" ");
	return datetime[1];
}

function fun_date_time_formated(datetime)
{
	datetime = datetime.split(" ");
	return fun_date_to_frontend(datetime[0])+ ' '+datetime[1];
}

function fun_success_message(msg)
{
	var device = fun_get_value("txt_device_type");
	if(device == 'phone') {
		Materialize.Toast.removeAll();
		Materialize.toast(msg, 5000)
	}
	else {
		toastr.clear();
	 toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "10000",
      "hideDuration": "10000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
    toastr.success(msg);
	}
     return true;
}

function fun_error_message(msg) {
	var device = fun_get_value("txt_device_type");
	if(device == 'phone') {
		Materialize.Toast.removeAll();
		Materialize.toast(msg, 5000)
	}
	else {
	    toastr.clear();
	    toastr.options = {
	      "closeButton": true,
	      "debug": false,
	      "newestOnTop": false,
	      "progressBar": true,
	      "positionClass": "toast-top-right",
	      "preventDuplicates": false,
	      "onclick": null,
	      "showDuration": "10000",
	      "hideDuration": "10000",
	      "timeOut": "50000",
	      "extendedTimeOut": "10000",

	      "showEasing": "swing",
	      "hideEasing": "linear",
	      "showMethod": "fadeIn",
	      "hideMethod": "fadeOut"
	    }
	    toastr.error(msg);
	}
    return true;
}

function fun_goto_menu(value)
{
	window.location = $("#base_url").val()+'products/'+value;
}

function fun_get_token()
{
	return $("input[name=_token]").val();
}

function add_to_cart(id,type) {

	var variants = true;
    var alert = '';
    var is_variant = $('[name="is_variant"]').val();
    if(is_variant == '1') {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(variants) {

	if($('[name="is_variant"]').val() == '1') {
        var variants = '&variants=' + $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
        .filter(function(form){return form.name != 'variants_count';})
        .map(function(config){ return config.value;}).toString();
    } else {
        var variants = '';
    }

	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/ADD-TO-CART",
		data:"id="+id+'&_token='+fun_get_token() + variants,
			success: function(response) {
				fun_hide_loader();	
				if(response.status==true)
				{
					if(type === 'wishlist' && device === 'phone'){
						fun_remove_wish_list(id);
					} else if(type==='wishlist'){
						fun_remove_wish_list(id);
					}
					fun_success_message(response.message);
					$("#spanCartCount").html(response.cart_data.length);
					$(".span_cart_count").html(response.cart_data.length);
					console.log(response.cart_data);
					if(response.ga == true){
						console.log("google addtocart event sent");
						ga('send', 'event', 'addtocart', 'click', 'product '+response.product.product_name, response.product.offer_price);
					}

					if(response.fb)
  						fbq('track', 'AddToCart');
					
				}
				else
				{
					fun_error_message(response.message);
				}	

				if(device == 'phone') 
					$('#modalNewCustomer').modal('close');
				 else 
					fun_close_modal('loginAndRegister');
			},
		error: function() {
			fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});	
	} else {
	fun_error_message('Please select product ' + alert.trim().slice(0,-1));
}
}



function fun_update_product_price(e){
	var price = $(e).find('[value="'+$(e).val()+'"]').attr('data-price');
	if(typeof price === "undefined") {
		$('.item-price').html('<b>Select product and it`s condition to see price.</b>');
	} else {
		$('.item-price').html('<h2>Rs. ' + price+'</h2>');

	}
}
function fun_update_product_condition(id) {
	$('.item-price').html('<b>Select product and it`s condition to see price.</b>');
	$.ajax({
            url: baseURL + "get-exchange-item-spec",
            type: 'GET',
            dataType: "JSON",
            data:"id="+$(id).val()+'&_token='+fun_get_token(),
            success: function(data) {
            	if(data.status === true) {
            		var html ='<option value="">Product Condition</option>';
            		$.each(data.spec, function(k,v){
            			html +='<option data-price="'+v.price+'" value="'+v.spec_id+'">'+v.item_spec+'</option>';
            		});
            			
					$('[name="exchange_product_spec"]').html(html);
            	}
        }
    });
}

function update_cart_qty(product_id, qty) {
    $.post(baseURL + 'update_cart_qty', {
        'product_id': product_id,
        '_token': fun_get_token(),
        'qty': qty
    }, function() {}, 'json').done(function(res) {
        if (res.status === true) {
            fun_success_message("Quantity updated successfully");
            location.reload();
        } else {
            fun_error_message("Error occured !!! Please try again.");
        }
    }).fail(function() {
        fun_error_message("Error occured !!! Please try after sometime.");
    })
}

function fun_add_to_cart(id,type)
{  
	var is_variant = $('[name="is_variant"]').val();
	var variants = true;
    var alert = '';
    if(is_variant == '1') {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(variants) {
	cart_id = id;
	if($('[name="is_variant"]').val() == '1') {
        var variants = '&variants=' + $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
                .filter(function(form){return form.name != 'variants_count';})
                .map(function(config){ return config.value;}).toString();
            } else {
                var variants = '';
            }
	$.ajax({
            url: baseURL + "check-login",
            type: 'GET',
            dataType: "JSON",
            data:"id="+id+'&_token='+fun_get_token() + variants,
            success: function(data) {
            	if(data.status === true) {
            		add_to_cart(id,type);
            		if(device == 'phone') 
						$('#modalNewCustomer').modal('close');
					 else 
						fun_close_modal('loginAndRegister');
					
            	} else {
            		fun_error_message('Please login before add product to your cart.');
            		if(device == 'phone') {
						$('#loginAndregister').modal('open');
					} else {
					fun_open_modal('loginAndRegister');
					}
            		
            	}
        }
    });
} else {
	fun_error_message('Please select product ' + alert.trim().slice(0,-1));
}
	
}



function fun_buy_now(id)
{  
	var is_variant = $('[name="is_variant"]').val();
	var variants = true;
    var alert = '';
    if(is_variant == '1') {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(variants) {
	cart_id = id;
	$.ajax({
            url: baseURL + "check-login",
            type: 'GET',
            dataType: "JSON",
            data:"id="+id+'&_token='+fun_get_token(),
            success: function(data) {
            	if(data.status === true) {
            		
            		buy_now(id);
            		if(device == 'phone') 
						$('#modalNewCustomer').modal('close');
					 else 
						fun_close_modal('loginAndRegister');
					
            	} else {
            		fun_error_message('Please login before add product to your cart.');
            		if(device == 'phone') {
						$('#loginAndregister').modal('open');
					} else {
						fun_open_modal('loginAndRegister');
					}
            		
            	}
        }
    });

    } else {
	fun_error_message('Please select product ' + alert.trim().slice(0,-1));
}
	
}

function fun_open_modal_exchange() {
var is_variant = $('[name="is_variant"]').val();
	var variants = true;
    var alert = '';
    if(is_variant == '1') {
        $('[name="variants[]"]').each(function(){
            if(!$('[name="variants'+$(this).val()+'"]').is(':checked')) { variants = false; alert += $('[name="variants'+$(this).val()+'"]').attr('data-alert') + ', ';}
        });
    }
    if(variants) {
	$('#buyWithExchange').modal('open'); }
	else {
		fun_error_message('Please select product ' + alert.trim().slice(0,-1));
	}

}


function fun_buy_with_exchange(product_id) {

	var item = $('[name="exchange_product"]').val();
	var spec = $('[name="exchange_product_spec"]').val();

	if(item ==='' || spec ==='' || typeof item === "undefined" || typeof spec === "undefined"){
		fun_error_message('Please select product and it`s condition.');
	} else {

		$.ajax({
            url: baseURL + "buy_with_exchange",
            type: 'POST',
            dataType: "JSON",
            data:"id="+product_id+'&_token='+fun_get_token() + '&'+ $('#exchangeProductForm').serialize(),
            success: function(response) {
				
				buy_now(product_id);
			
			},
		error: function() {
			console.log("Error occured !!! Please try after sometime.");
		}
    });
	}

	
}

function buy_now(id)
{

	if($('[name="is_variant"]').val() == '1') {
        var variants = '&variants=' + $('#product_variants').serializeArray()
        .filter(function(form){ return form.name!='variants[]';})
        .filter(function(form){return form.name != 'variants_count';})
        .map(function(config){ return config.value;}).toString();
    } else {
        var variants = '';
    }

	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/ADD-TO-CART",
		 data:"id="+id+'&_token='+fun_get_token() + variants,
			success: function(response) {
				// fun_hide_loader();	
				
				if(response.fb)
  					fbq('track', 'AddToCart');

  				if(response.ga){
					// console.log("google addtocart event sent");
					ga('send', 'event', 'addtocart', 'click', 'product '+response.product.product_name, response.product.offer_price);
				}
				// fun_success_message(response.message);
				// window.location = baseURL+'my-cart';

				fun_success_message("Product added to cart, please wait we're redirecting to cart page");

				setTimeout(function(){ window.location = baseURL+'my-cart'; },1000);
				
				// if(response.status==true)
				// {
				// 	window.location = baseURL+'my-cart';
				// }
				// else
				// {
				// 	fun_error_message(response.message);
				// }	
			},
		error: function() {
			// fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});		
}

function fun_add_to_wish_list(id,ele,type)
{	
	if(device == "phone"){
		if(customer.customer_id !=''){
			console.log(customer.customer_id);
			$(ele).find('i').removeClass('fa-heart-o').addClass('fa-heart');
		}
		else{
			url = baseURL+'login-register?redirect='+location.href+'&type=wishlist&product_id='+id+'&msg=Please login to add product to wishlist';
			fun_goto_url(url);
			return true;
		}
	}
	
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/ADD-TO-WISH-LIST",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				// fun_hide_loader();	
				if(response.status==true)
				{
					// window.location = window.location.href;
					fun_success_message(response.message);
					// console.log(response.cart_data);
					$(".span_wish_list_count").html(response.wish_list_count);

					// fun_set_cart_div(response.cart_data);

					if(device == "phone" && type == true){
						fun_remove_cart_item(id);
					} else {
						location.reload(true);
					}

				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			// fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});	
}

function fun_add_to_compare(id)
{
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/ADD-TO-COMPARE",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				// fun_hide_loader();	
				if(response.status==true)
				{
					window.location = window.location.href;
					// fun_success_message(response.message);
					console.log(response.cart_data);

					// fun_set_cart_div(response.cart_data);
				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			// fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});	
}

function fun_set_cart_div(records)
{
	var template = '';
	var totalAmount = 0;
	$(records).each(function(index,value){
		image = value.main_image;
		template = template + '<li> <div class="cart-item product-summary"> <div class="row margin-b-20"> <div class="col-xs-4"> <div class="image">';
        template = template + ' <a href="'+baseURL+'product/'+value.product_id+'"><img src="'+baseURL+'public/app-source/product-images/'+image+'" alt=""></a> </div> </div>';
		template = template + ' <div class="col-xs-7"> <h3 class="name"> <a href="'+baseURL+'site/product-detail/'+value.product_id+'">'+fun_reduce_name_length(value.product_name,20)+'</a></h3>';
        template = template + ' <div class="price">';
        product_price = value.price;
        template = template + 'Rs. ' + product_price;
		template = template + '</div> </div> <div class="col-xs-1 action">';
		template = template + '<a class="cursor" onclick="fun_remove_cart_item(\''+value.product_id+'\')"> <img src="public/images/svg/recycle-bin.svg" width="14" height="14" class="buy-i pull-right"></a>';
		template = template + '</div> </div> </div> </li> ';

		totalAmount = parseInt(totalAmount)+parseInt(product_price);

	});	
	$("#spanCartCount").html(records.length);	
	if(records.length == 0){
		$("#liCartItems").html("<li> <p> No Cart Items </p> </li>");
	}
	else {
		template = template +'<div> <div class="clearfix"> </div> <hr> <div class="clearfix cart-total"> <div class="pull-right">';
		template = template +'<span class="text">Sub Total :</span><span class="price">Rs.'+totalAmount+' </span>';
		template = template +'</div><div class="clearfix"></div> <a href="'+baseURL+'site/view-cart" class="btn btn-upper btn-primary btn-block m-t-20">View Cart</a></div></div>';
		$("#liCartItems").html(template);
	}
}

function fun_reduce_name_length(name,str_count)
{
	var len =  name.length;
	if(len > str_count)
		return name.substring(1, str_count)+'...';
	else 
		return name.substring(1, str_count);
}	

function fun_remove_cart_item(id)
{
	// fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/REMOVE-FROM-CART",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				// fun_hide_loader();	
				if(response.status==true)
				{
					window.location = window.location.href;
				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			// fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});	
}

function fun_remove_wish_list(id)
{	
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/REMOVE-FROM-WISH-LIST",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				// fun_hide_loader();	
				if(response.status==true)
				{
					window.location = window.location.href;
				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			// fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});	
}

function fun_remove_compare(id)
{
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/REMOVE-FROM-COMPARE",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				// fun_hide_loader();	
				if(response.status==true)
				{
					window.location = window.location.href;
				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			// fun_hide_loader();
			console.log("Error occured !!! Please try after sometime."); //Error
		}
	});		
}

function fun_get_addresses(id)
{
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/checkout-process/GET-ADDRESS",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					if(response.device == 'phone'){

						fun_set_address_mobile_div(response.address);
					}
					else 
						fun_set_address_div(response.address);
				}
				else {
					$("#div_checkout_address").html(' <p class="padding-20"> No Address Details Found </p>');
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});		
}

function fun_set_address_div(records)
{
	var device = fun_get_value('txt_device_type');
	if(device == 'phone'){
		fun_set_address_mobile_div(records);
	}
	else {
		var template = '';
		var sno = 1;
		var last_address = '';
		$(records).each(function(index,value){
			template = template + '<div class="col-md-6"> <div class="address-box"> ';
			template = template + '<p> '+value.name+'  <span onclick="fun_delete_address('+value.address_id+')"> <img src="public/images/svg/recycle-bin.svg" width="14" height="14" class="buy-i pull-right cursor"> </span> <span onclick="fun_edit_address('+value.address_id+')"> <img src="public/images/svg/edit.svg" width="14" height="14" class="buy-i pull-right cursor ad-edit"> </span> </p> ';
			template = template + '<div> ';
			template = template + '<p> '+value.address_line1.replace(',','')+',';
			if(value.address_line2 !='')
				template = template + value.address_line2.replace(',','')+',';
			template = template + '</p>';
			template = template + '<p> '+value.city+', </p> ';
			template = template + '<p> '+value.state+' - '+value.pincode+' </p> ';
			if(value.mobile !=''){
				template = template + '<p> MOBILE:- '+value.mobile+'</p> ';
			}
			template = template + '<button id="butAddressSelect'+value.address_id+'" onclick="fun_select_address('+value.address_id+')" class="btn btn-primary btn-sm"> Select </button> ';
			template = template + '</div> </div> </div>';
			sno++;
			last_address = value.address_id;
		});

		if(records.length==0)
			$("#div_checkout_address").html('');
		else 
			$("#div_checkout_address").html(template);					

		if(fun_get_value('txt_address_added')=='yes') {
			fun_select_address(last_address);
		}
	}
}

function fun_set_address_mobile_div(records)
{
	var template = '';
	var sno = 1;
	var last_address = '';
	$(records).each(function(index,value){
		template = template + '<div class="col-md-6 card padding-15"> <div class="address-box"> ';
		template = template + '<p> '+value.name+'  <span class="right" onclick="fun_delete_address('+value.address_id+')"> <img src="public/images/svg/recycle-bin.svg" width="14" height="14" class="buy-i pull-right"> </span> <span class="right margin-r-15" onclick="fun_edit_address('+value.address_id+')"> <img src="public/images/svg/edit.svg" width="14" height="14" class="buy-i pull-right cursor ad-edit"> </span> </p> ';
		template = template + '<div> ';
		template = template + '<p> '+value.address_line1+', </p> ';
		if(value.address_line2 !='')
			template = template + '<p> '+value.address_line2+', </p> ';
		template = template + '<p> '+value.city+', </p> ';
		template = template + '<p> '+value.state+' - '+value.pincode+' </p> ';
		if(value.mobile !=''){
				template = template + '<p> MOBILE:- '+value.mobile+'</p> ';
		}
		template = template + '<button id="butAddressSelect'+value.address_id+'" onclick="fun_select_address('+value.address_id+')" class="btn btn-primary btn-sm"> Select </button> ';
		template = template + '</div> </div> </div>';
		sno++;
		last_address = value.address_id;
	});

	if(records.length==0)
		$("#div_checkout_address").html('');
	else 
		$("#div_checkout_address").html(template);					

	if(fun_get_value('txt_address_added')=='yes') {
		fun_select_address(last_address);
	}
}

function fun_select_address(id)
{
	$("#butAddressSelect"+id).removeClass("btn-primary").addClass("btn-success");
	$("#butAddressSelect"+id).html("Selected");
	fun_set_value("txtSelectedAddress",id);
	if(preAddress!='' && preAddress!=id) {
		$("#butAddressSelect"+preAddress).removeClass("btn-success").addClass("btn-primary");
		$("#butAddressSelect"+preAddress).html("Select");
	} 
	preAddress = id;
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/checkout-process/SET-SELECTED-ADDRESS",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				fun_continue_to_payment();
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});
}

function fun_continue_to_payment()
{
	var selectedAddress = fun_get_value("txtSelectedAddress");
	if(selectedAddress == '' )
	{
		fun_error_message("Please select any one address");
	}
	else {
		var checkout_option = fun_get_value('txt_payment_option');
		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/checkout-process/VERIFY-DELIVERY",
			 data:"selectedAddress="+selectedAddress+'&checkout_option='+checkout_option+'&_token='+fun_get_token(),
				success: function(response) {
					fun_hide_loader();
					if(response.status==true) {
						// $("#accordinSection4").addClass("collapsed");
						$("#div_payment_body").removeClass("hide");
						if(response.device == 'phone') {
							$("#div_checkout_payment_section").trigger("click");
							if(response.cod=='no') 
								$("#div_cod").addClass("hide");
							else 
								$("#div_cod").removeClass("hide");
						}
						else {
							$("#accordinSection4").attr("data-toggle","collapse");
					        $("#accordinSection4").attr("href","#collapseFive");
							fun_trigger_click("accordinSection4");

							if(response.cod=='no') {
								$(".no-cod").addClass("hidden");
							}
							else {
								$(".no-cod").removeClass("hidden");
							}
						}
					}
					else {
						$("#div_payment_body").addClass("hide");
						fun_error_message(response.message);
						$("#accordinSection4").addClass("collapsed");
				        $("#accordinSection4").removeAttr("data-toggle");
				        $("#accordinSection4").removeAttr("href");
					}
				},
			error: function() {
				fun_error_message("Error occured please try after some time");
			}
		});		
	}
}

function fun_trigger_click(id)
{
	$("#"+id).trigger("click");
}

function fun_pay_by_cash_on_delivery()
{
	var address = fun_get_value("txtSelectedAddress");
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/checkout-process/CREATE-ORDER",
		 data:"address="+address+'&type=CASH-ON-DELIVERY&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					window.location = baseURL+'order-status-success/'+response.order_id;
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});
}

function fun_edit_address(id)
{
	addressID = id;
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/address-process/GET",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					fun_set_value("txt_customer_id",response.address.customer_id);
					fun_open_modal("modalEditCustomerAddress");
					fun_set_value("txtEditAddressCaption",response.address.name);
					fun_set_value("txtEditAddressLine1",response.address.address_line1);
					fun_set_value("txtEditAddressLine2",response.address.address_line2);
					fun_set_value("txtEditCity",response.address.city);
					fun_set_value("txtEditState",response.address.state);
					fun_set_value("txtEditPincode",response.address.pincode);
					fun_set_value("txtEditMobileNumber",response.address.mobile);
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});		
}

function fun_delete_address(id)
{
	addressID = id;
	fun_open_modal("modalDeleteCustomerAddress");
}

function fun_delete_address_confirm()
{
	var customerID = fun_get_value("txt_customer_id");
	fun_show_modal_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/address-process/DELETE",
		 data:"addressID="+addressID+'&customerID='+customerID+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_modal_loader();
				if(response.status==true) {
					fun_close_modal("modalDeleteCustomerAddress");
					fun_set_address_div(response.address);
					fun_success_message(response.message);
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});	
}

function fun_pay_10_percent_online(type)
{   

	var orderID = fun_get_value("txt_order_id");
	var address = fun_get_value("txtSelectedAddress");
	if(orderID!='')
	{
		if(type=='payu') {
			window.location = baseURL+'paymentprocess/payumoney-payment/'+orderID;
		}
		else if(type=='razorpay') {
			fun_verify_razorpay_payment(orderID);
		}
	}
	else {
		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/checkout-process/CREATE-ORDER",
			 data:"address="+address+'&type=ONLINE&_token='+fun_get_token() + '&pay_amount=10',
				success: function(response) {
					fun_hide_modal_loader();
					console.log(type);
					if(response.status==true) {
						fun_set_value("txt_order_id",response.order_id);
						if(type=='payu') {
							window.location = baseURL+'paymentprocess/payumoney-payment/'+response.order_id;
							// console.log(baseURL+'paymentprocess/payumoney-payment/'+response.order_id);
						}
						else if(type=='razorpay') {
							console.log("coming to razorpay success");
							fun_verify_razorpay_payment(response.order_id);
						}
						else if(type == 'ccavenue') {
							window.location = baseURL+'paymentprocess/v2/goto-ccavenue-payment-api/'+response.order_id;	
							// console.log(baseURL+'paymentprocess/v2/goto-ccavenue-payment-api/'+response.order_id);
						}
					}
					else {
						fun_error_message(response.message);	
					}
				},
			error: function() {
				fun_error_message("Error occured please try after some time");
			}
		});	
	}
}

function fun_pay_online(type)
{   

	var orderID = fun_get_value("txt_order_id");
	var address = fun_get_value("txtSelectedAddress");
	if(orderID!='')
	{
		if(type=='payu') {
			window.location = baseURL+'paymentprocess/payumoney-payment/'+orderID;
		}
		else if(type=='razorpay') {
			fun_verify_razorpay_payment(orderID);
		} else if(type == 'ccavenue') {
							window.location = baseURL+'paymentprocess/v2/goto-ccavenue-payment-api/'+orderID;	
		}
	}
	else {
		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/checkout-process/CREATE-ORDER",
			 data:"address="+address+'&type=ONLINE&_token='+fun_get_token()  + '&pay_amount=100',
				success: function(response) {
					fun_hide_modal_loader();
					console.log(type);
					if(response.status==true) {
						fun_set_value("txt_order_id",response.order_id);
						if(type=='payu') {
							window.location = baseURL+'paymentprocess/payumoney-payment/'+response.order_id;
						}
						else if(type=='razorpay') {
							console.log("coming to razorpay success");
							fun_verify_razorpay_payment(response.order_id);
						}
						else if(type == 'ccavenue') {
							window.location = baseURL+'paymentprocess/v2/goto-ccavenue-payment-api/'+response.order_id;	
						}
					}
					else {
						fun_error_message(response.message);	
					}
				},
			error: function() {
				fun_error_message("Error occured please try after some time");
			}
		});	
	}
}

function fun_verify_razorpay_payment(id)
{
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/razorpay-process/VERIFY",
		data: 'id='+id+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					//window.location= baseURL+'site/compare';
					// 1 - amount
					// 2 - order ID 
					// 3 - RazorPay ID
					// 4 - Customer Name
					// 5 - Payment For
					fun_pay_using_razor_pay(response.amount_payable,response.order.order_id,response.razor_pay_key,response.customer.firstname,"");
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});		
}

function fun_pay_using_razor_pay(amount,paymentID,razPayID,CustomerName,PaymentDesc)
{
	var email = fun_get_value_we('txt_customer_email');
	var options = {
	    "key": razPayID,
	    "amount": amount*100, 
	    "name": CustomerName,
	    "description": PaymentDesc,
	    "handler": function (response){
	       // alert(response.razorpay_payment_id);
	       if(PaymentDesc == 'gift')
	       	fun_gift_payment_success_razorpay(response.razorpay_payment_id,paymentID);
	       else 
	       	fun_payment_success_razorpay(response.razorpay_payment_id,paymentID);
	    },
	    "prefill": {
	        "name": CustomerName,
	        "email": email,
	        // "mobile": "9731658263"
	    },
	    "notes": {
	        "address": ""
	    },
	    "theme": {
	        "color": "#00AEFF"
	    }
	};
	var rzp1 = new Razorpay(options);
	rzp1.open();	
}

function fun_payment_success_razorpay(rzPaymentID,orderID)
{
	console.log(rzPaymentID+orderID);
	fun_show_loader();
		$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/razorpay-process/SUCCESS",
		 data:'orderID='+orderID+'&razorpayID='+rzPaymentID+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();	
				if(response.status==true)
				{	
					window.location=baseURL+"order-status-success/"+response.encryptedOrderID;
				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			fun_hide_loader();
			fun_modal_error("Error occured !!! Please try after sometime.");
		}
	});
}

function fun_gift_payment_success_razorpay(rzPaymentID,orderID)
{
	fun_show_loader();
		$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/razorpay-process/GIFT-SUCCESS",
		 data:'orderID='+orderID+'&razorpayID='+rzPaymentID+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();	
				if(response.status==true)
				{	
					window.location=baseURL+"gift-payment-status/"+response.encryptedOrderID;
				}
				else
				{
					fun_error_message(response.message);
				}	
			},
		error: function() {
			fun_hide_loader();
			fun_modal_error("Error occured !!! Please try after sometime.");
		}
	});	
}

function fun_pay_razorpay_gift_card()
{
	var id = fun_get_value("encrypted_id");
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/razorpay-process/GIFT-VERIFY",
		data: 'id='+id+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					//window.location= baseURL+'site/compare';
					// 1 - amount
					// 2 - order ID 
					// 3 - RazorPay ID
					// 4 - Customer Name
					// 5 - Payment For
					fun_pay_using_razor_pay(response.amount_payable,response.gift.card_id,response.razor_pay_key,response.customer.firstname,"gift");
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});		
}

function fun_feature_filter(name,value,id)
{
	//array_insert_remove(name+'-'+value,filterFeature);
	var sno = $("#"+id).data("sno");
	console.log(id);
	var index = filterFeature.indexOf(name+'|'+value);
    if (index > -1){
        filterFeature.splice(index, 1);
        if(device == 'desktop') {
        $("#checkboxFilter"+sno).prop("checked", false); 
        } else {
        	$("#"+id).removeClass('active-filter');
        }
    }
    else{
        filterFeature.push(name+'|'+value);
        if(device == 'desktop') {
        $("#checkboxFilter"+sno).prop("checked", true);
         } else {
        	$("#"+id).addClass('active-filter');
        }
    }
	console.log(filterFeature.toString());
	$("#txtFilterFeatures").val(filterFeature.toString());
	fun_search_products_based_on_filter();
}

 function fun_search_with_price_range()
  {
    var fullurl = fun_get_value_we("txtFullURL");
    var priceRange = $("#slider-range").attr("value");
    priceRange = priceRange.split(",");
    //window.location = fullurl+'?minPrice='+priceRange[0]+'&maxPrice='+priceRange[1];
    fun_set_value("txtFilterMinPrice",priceRange[0]);
    fun_set_value("txtFilterMaxPrice",priceRange[1]);

    fun_search_products_based_on_filter();
  }

function fun_search_products_based_on_filter()
{
var device = fun_get_value("txt_device_type");
if(device == 'phone') {

    if(fun_get_value('txtPriceFilter') === 'true'){
    		var values = slider.noUiSlider.get();
			fun_set_value("txtFilterMinPrice",parseInt(values[0].replace('&#8377;','').replace(/\,/g,'')));
			fun_set_value("txtFilterMaxPrice",parseInt(values[1].replace('&#8377;','').replace(/\,/g,'')));
    }
	
}

  var fullURL = fun_get_value_we("txtFullURL");
  var sortBy = fun_get_value_we("txtFilterSort");
  var orderBy = fun_get_value_we("txtFilterOrder");
  var minPrice = fun_get_value_we("txtFilterMinPrice");
  var maxPrice = fun_get_value_we("txtFilterMaxPrice");
  var filters = fun_get_value_we("txtFilterFeatures");

  // var sortBy = '';
  // var orderBy = '';
  // var filters = '';
  if(sortBy == '' && orderBy == '' && minPrice == '' && maxPrice == '' && filters == '')
  	fullURL = fullURL;
  else {
  	console.log(fullURL);
  	console.log(fullURL.indexOf("?"));
  	if(fullURL.indexOf("?")>0)
  		fullURL = fullURL+'&sort_by='+sortBy+'&order_by='+orderBy+'&minPrice='+minPrice+'&maxPrice='+maxPrice+'&filters='+filters;
  	else 
  		fullURL = fullURL+'?sort_by='+sortBy+'&order_by='+orderBy+'&minPrice='+minPrice+'&maxPrice='+maxPrice+'&filters='+filters;
  }
  	

  console.log(fullURL);
  window.location = fullURL;
}

function fun_set_filter(type,value,type1,value1)
{
  $("#"+type).val(value);
  $("#"+type1).val(value1);

  fun_search_products_based_on_filter();
}

function array_insert_remove(value, array)
{
    var index = array.indexOf(value);
    if (index > -1)
        array.splice(index, 1);
    else
        array.push(value);
}

function fun_remove_filter(type,value)
{
	if(type=='price' || type == 'product_name' || type == 'discount' || type == 'discount_per' || type == 'created_at' || type == 'viewed' || type == 'offer_price'){
		fun_set_value("txtFilterSort","");
		fun_set_value("txtFilterOrder","");
	}

	if(type=='minPrice' && value=='maxPrice')
	{
		fun_set_value("txtFilterMinPrice","");
		fun_set_value("txtFilterMaxPrice","");	
	}
	fun_search_products_based_on_filter();
}

function fun_remove_product_filter(name,value)
{
	var index = filterFeature.indexOf(name+'|'+value);
    if (index > -1){
        filterFeature.splice(index, 1);
    }
    else{
        filterFeature.push(name+'|'+value);
    }
	
	$("#txtFilterFeatures").val(filterFeature.toString());
	fun_search_products_based_on_filter();	
}

function fun_get_all_addresses(id)
{
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/checkout-process/GET-ADDRESS",
		 data:"id="+id+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					fun_set_address_div_account_page(response.address);
				}
				else {
					$("#div_checkout_address").html(' <p class="padding-20"> No Address Details Found </p>');
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});		
}

function fun_delete_address_confirm_account()
{
	var customerID = fun_get_value("txt_customer_id");
	fun_show_modal_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/address-process/DELETE",
		 data:"addressID="+addressID+'&customerID='+customerID+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_modal_loader();
				if(response.status==true) {
					fun_close_modal("modalDeleteCustomerAddress");
					fun_set_address_div_account_page(response.address);
					fun_success_message(response.message);
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});	
}

function fun_set_address_div_account_page(records)
{
	var device = fun_get_value("txt_device_type");
	if(device == 'phone') {
		fun_set_address_div_mobile_account_page(records);
	}
	else {
		var template = '';
		var sno = 1;
		$(records).each(function(index,value){
			template = template + '<div class="col-md-6"> <div class="address-box"> ';
			template = template + '<p> '+value.name+'  <span onclick="fun_delete_address('+value.address_id+')"> <img src="public/images/svg/recycle-bin.svg" width="14" height="14" class="buy-i pull-right cursor"> </span> <span onclick="fun_edit_address('+value.address_id+')"> <img src="public/images/svg/edit.svg" width="14" height="14" class="buy-i pull-right cursor ad-edit"> </span> </p> ';
			template = template + '<div> ';
			template = template + '<p> '+value.address_line1.replace(',','')+', ';
			if(value.address_line2 !='')
				template = template +value.address_line2.replace(',','')+', ';
			template = template + '</p>';
			template = template + '<p> '+value.city+', </p> ';
			template = template + '<p> '+value.state+' - '+value.pincode+' </p> ';
			if(value.mobile !=''){
				template = template + '<p> MOBILE:- '+value.mobile+'</p> ';
			}
			template = template + '</div> </div> </div>';
			sno++;
		});
		if(records.length==0)
			$("#div_checkout_address").html('');
		else 
			$("#div_checkout_address").html(template);					
	}
}

function fun_set_address_div_mobile_account_page(records)
{
	var template = '';
	var sno = 1;
	$(records).each(function(index,value){
		template = template + '<div class="col-md-6 card padding-15"> <div class="address-box"> ';
		template = template + '<p> '+value.name+'  <span onclick="fun_delete_address('+value.address_id+')"> <img src="public/images/svg/recycle-bin.svg" width="14" height="14" class="buy-i pull-right cursor"> </span> <span onclick="fun_edit_address('+value.address_id+')"> <img src="public/images/svg/edit.svg" width="14" height="14" class="buy-i pull-right cursor ad-edit"> </span> </p> ';
		template = template + '<div> ';
		template = template + '<p> '+value.address_line1+', </p> ';
		if(value.address_line2 !='')
			template = template + '<p> '+value.address_line2+', </p> ';
		template = template + '<p> '+value.city+', </p> ';
		template = template + '<p> '+value.state+' - '+value.pincode+' </p> ';
		if(value.mobile !=''){
				template = template + '<p> MOBILE:- '+value.mobile+'</p> ';
			}
		template = template + '</div> </div> </div>';
		sno++;
	});
	if(records.length==0){
		$("#div_no_address").removeClass("hide");
		$("#div_checkout_address").addClass("hide");
		$("#div_checkout_address").html('');
	}
	else{
		$("#div_no_address").addClass("hide");
		$("#div_checkout_address").removeClass("hide");
		$("#div_checkout_address").html(template);
	} 
		
}

function fun_apply_coupon()
{
	var coupon = fun_get_value('txt_coupon');
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/checkout-process/APPLY-COUPON",
		 data:"coupon="+coupon+'&_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					// fun_success_message(response.message);
					window.location = window.location.href;
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_error_message("Error occured please try after some time");
		}
	});		
}

function fun_check_pincode_availability(type)
{
	var pincode = fun_get_value('txt_pincode');
	$("#p_pincode_error").html("");
	if(pincode == '')
		fun_error_message("Please enter the pincode");
	else{
		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/pincode-process/CHECK",
			 data:"pincode="+pincode+'&_token='+fun_get_token()+'&type='+type,
				success: function(response) {
					fun_hide_loader();
					if(response.status==true) {
						fun_success_message(response.message);
						// window.location = window.location.href;
						$("#p_selected_pincode").html(pincode+' - Selected pincode having a delivery availability');
						$("#div_pincode_check").css("display","none");
						$("#div_selected_pincode").css("display","block");
					}
					else {
						fun_error_message('Oops! We dont deliver to this location yet.');
						// $("#p_pincode_error").html(response.message);
						if(type==1)
							$("#p_pincode_error").html("Oops! we dont deliver to this location yet. Delivery now available only in central Kerala");
						else 
							$("#p_pincode_error").html("Oops! we dont deliver to this location yet.");
						// fun_error_message(response.message);	
					}
				},
			error: function() {
				fun_hide_loader();
				fun_error_message("Error occured please try after some time");
			}
		});		
	} 
}

function fun_clear_selected_pincode()
{
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/pincode-process/CLEAR",
		 data:'_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					$("#div_pincode_check").css("display","block");
					$("#div_selected_pincode").css("display","none");
				}
				else {
					fun_error_message(response.message);	
				}
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});			
}

function fun_cancel_customer_order(id)
{
	cancel_product_id = id
	fun_open_modal("modal_cancel_order");
}

function fun_return_customer_order(id)
{
	return_product_id = id
	fun_open_modal("modal_return_order");	
}

function fun_send_account_reset_otp()
{
	if(!$('#div_get_otp form button.otp').hasClass('sent')) {

		$('#div_get_otp form button.otp').addClass('sent').attr('disabled','true');
		setTimeout(function(){
			$('#div_get_otp form button.otp').removeClass('sent').removeAttr('disabled');
		},15000);



		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/account-reset-password-process/SEND-OTP",
			 data:'_token='+fun_get_token(),
				success: function(response) {
					fun_hide_loader();
					if(response.status==true) {
						fun_success_message(response.message);
					}
					else {
						fun_error_message(response.message);	
					}
				},
			error: function() {
				fun_hide_loader();
				fun_error_message("Error occured please try after some time");
			}
		});	

	}
	
}

function fun_submit_account_otp()
{
	var otp = fun_get_value('otp');
	if(otp == ''){
		fun_error_message("Please enter the otp");
	}
	else {
		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/account-reset-password-process/VERIFY",
			 data:'_token='+fun_get_token()+'&otp='+otp,
				success: function(response) {
					fun_hide_loader();
					if(response.status==true) {
						fun_success_message(response.message);
						$("#div_reset_password").css("display","block");
						$("#div_get_otp").css("display","none");
					}
					else {
						fun_error_message(response.message);	
					}
				},
			error: function() {
				fun_hide_loader();
				fun_error_message("Error occured please try after some time");
			}
		});	
	}
}

function fun_account_reset_password_process()
{
	var new_pass = fun_get_value('txt_new_password');
	var con_pass = fun_get_value('txt_confirm_password');

	if(new_pass === '' ||  con_pass==='') {
		fun_error_message("New password and confirm password does not match");
		return false;
	} else {

	if(new_pass != con_pass) {
		fun_error_message("New password and confirm password does not match");
	}
	else {
		fun_show_loader();
		$.ajax({								
			type: "POST",
			dataType: "JSON",
			url: baseURL+"ajax/account-reset-password-process/RESET",
			 data:'_token='+fun_get_token()+'&new_pass='+new_pass,
				success: function(response) {
					fun_hide_loader();
					if(response.status==true) {
						fun_success_message(response.message);
						$("#div_reset_password").css("display","none");
						$("#div_get_otp").css("display","block");

						fun_set_value("txt_new_password",'');
						fun_set_value("txt_confirm_password",'');
						fun_set_value("otp",'');
					}
					else {
						fun_error_message(response.message);	
					}
				},
			error: function() {
				fun_hide_loader();
				fun_error_message("Error occured please try after some time");
			}
		});	
	}

	}

	
}

function fun_register_continue_resend(id)
{
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/account-reset-password-process/REG-CONTINUE-RESEND",
		 data:'_token='+fun_get_token()+'&id='+id,
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					fun_success_message(response.message);
				}
				else {
					fun_error_message(response.message);
				}
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});	
}

function fun_resend_otp_checkout()
{	
	var enc_id = fun_get_value("txt_encrypted_id");
	console.log(enc_id);
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/checkout-process/RESEND-OTP",
		 data:'_token='+fun_get_token()+'&enc_id='+enc_id,
			success: function(response) {
				fun_hide_loader();
				if(response.status==true) {
					fun_success_message(response.message);
				}
				else {
					fun_error_message(response.message);
				}
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});	
}

function fun_change_products_view_type(type)
{
	// fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"product-process/PRODUCT-LIST-TYPE",
		 data:'_token='+fun_get_token()+'&type='+type,
			success: function(response) {
				// fun_hide_loader();
				window.location = window.location.href;
				if(response.status==true) {
					// fun_success_message(response.message);

				}
				else {
					// fun_error_message(response.message);
				}
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});	
	$('.wow.fadeInUp.animated').css('height','auto');
}

function fun_load_time_update()
{
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/customer-process/UPDATE-PAGE-LOAD-TIME",
		 data:'_token='+fun_get_token(),
			success: function(response) {
				
			},
		error: function() {
			
		}
	});			
}

function fun_number_validation(evt,id)
{
	$("#"+id).css("border-color","#989898");

	var charCode = (evt.which) ? evt.which : evt.keyCode;
	if(charCode==8 )
	return true;
	if(charCode == 46 || charCode == 110)
	return false;
	if(length==10)
	return false;

	if (charCode == 190 || charCode == 46)
	return true;
	if (charCode > 31 && (charCode < 48 || charCode > 57))
	return false;
	return true;
}

function fun_set_default_image(url)
{
   $(".drift-demo-trigger").attr("src",url);
   $(".drift-demo-trigger").attr("data-zoom",url);

   new Drift(document.querySelector('.drift-demo-trigger'), {
       paneContainer: document.querySelector('.drift-zoom-section'),
       // inlinePane: 900,
       // inlineOffsetY: -85,
       zoomFactor: 2,
       // containInline: true,
       // hoverBoundingBox: true
     });
}

function fun_load_product_images()
{
	// $(".product-lazy-image").each(function(){
 //        var image = $(this).attr('lsrc');
 //        // console.log(image);
 //        $(this).attr("src",image);
 //     });

	// $(".product-image").each(function(){
	//     var image = $(this).attr('lsrc');
	//     $(this).attr("src",image);
	//  });

	//  $(".list-image").each(function(){
	//     var image = $(this).attr('lsrc');
	//     $(this).attr("src",image);
	//  });
}

function fun_price_update(qty,id,available_qty,sub_stock)
{
   if(available_qty < qty && sub_stock == 1){
      fun_error_message("!!! Oops we dont have enough stock....");
      $("#txt_product_qty_"+id).val("1");
      // fun_set_value('txt_product_qty_'+id,"1");
   }
   else {
      var price = fun_get_value('txt_price'+id)*qty;
      $("#span_grand_total"+id).html(' ₹ '+price);  
      fun_clear_toaster();
   }
}

function fun_clear_toaster()
{
	toastr.clear();
}

function fun_set_gift_card_price(price)
{
	if(price>=10) {
		$("#div_price_500,#div_price_1000,#div_price_1500,#div_price_2000,#div_price_5000,#div_price_10000").removeClass('price-active');
		$("#div_price_"+price).addClass("price-active");
		fun_set_value("txt_selected_gift_price",price);
		if(fun_get_value("txt_gift_price") == '')
			$(".selected-gift-voucher").html("Rs. "+price);
		else 
			$(".selected-gift-voucher").html("Rs. "+fun_get_value("txt_gift_price"));
	}
	else {
		fun_error_message("Invalid gift card value should be minimum Rs.10");
	}
}

function fun_other_gift_amount()
{ 
	if(fun_get_value("txt_gift_price") == '')
		$(".selected-gift-voucher").html("Rs. "+fun_get_value("txt_selected_gift_price"));
	else 
		$(".selected-gift-voucher").html("Rs. "+fun_get_value("txt_gift_price"));
}

function fun_goto_url(url)
{
	window.location = url;
}

function go_back() {
    window.history.back();
}

function fun_share_social(type)
{
	var url = fun_get_value("txt_full_url");
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/share",
		 data:'_token='+fun_get_token()+'&type='+type+'&url='+url,
			success: function(response) {
				fun_hide_loader();
				if(type=="facebook")
					popupwindow(response.facebook,"Share",800,600);
				else if(type == "google")
					popupwindow(response.gplus,"Share",800,600);
				else if(type == 'twitter')
					popupwindow(response.twitter,"Share",800,600);
				
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});			
}

function popupwindow(url, title, w, h) {
  var left = (screen.width/2)-(w/2);
  var top = (screen.height/2)-(h/2);
  return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
} 


(function($){


$.fn.alapattNotify = function(options) {

var settings = $.extend({
  title: "Notification",
  options: {
    body: "",
    icon: "",
    lang: 'en-US',
    onClose: "",
    onClick: "",
    onError: ""
  }
}, options);

this.init = function() {
    var notify = this;
  if (!("Notification" in window)) {
   
  } else if (Notification.permission === "granted") {

    var notification = new Notification(settings.title, settings.options);
    
    notification.onclose = function() {
        if (typeof settings.options.onClose == 'function') { 
            settings.options.onClose();
        }
    };

    notification.onclick = function(){
        if (typeof settings.options.onClick == 'function') { 
            settings.options.onClick();
        }
    };

    notification.onerror  = function(){
        if (typeof settings.options.onError  == 'function') { 
            settings.options.onError();
        }
    };

  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function(permission) {
      if (permission === "granted") {
        notify.init();
      }

    });
  }

};

this.init();
return this;
};



})(jQuery);

function fun_apply_reward_points()
{
	fun_show_loader();
	$.ajax({								
		type: "POST",
		dataType: "JSON",
		url: baseURL+"ajax/apply-reward-point",
		 data:'_token='+fun_get_token(),
			success: function(response) {
				fun_hide_loader();
				if(response.status == true)
					window.location = window.location.href;
				else 
					fun_error_message(response.message);
			},
		error: function() {
			fun_hide_loader();
			fun_error_message("Error occured please try after some time");
		}
	});			
}

function fun_copy_reward_link() {
   var copyText = document.getElementById("reward-link");
   copyText.select();
   document.execCommand("Copy");
   alert("Copied the text: " + copyText.value);
}

function fun_set_payment(type) {

	$('.payment-method').removeClass('open');
	$('.payment-method.' + type).addClass('open');

}

   var items = [];
      
      function winPos(win) {
          return { x: 0, y: 0, x2: win.width() - 1, y2: win.height() - 1 };
      }
     
      function pos(win, el) {
          var p = el.offset();
          var x = p.left - win.scrollLeft();
          var y = p.top - win.scrollTop();
          return { x: x, y: y, x2: x + el.width() -1, y2: y + el.height() - 1 };
      }
      
      function intersects(a, b) {
          return !(a.x2 < b.x || a.x > b.x2 || a.y2 < b.y || a.y > b.y2);
      }
      
      function check(win, w, item) {
          var p = pos(win, $(item.el));
          var s = intersects(w, p);
          if (s != item.shown) {
              item.shown = s;
              (s ? item.show : item.hide).call(item.el);
          }
      }



  (function($){

     $.fn.visible = function(show, hide){
          var win = $(window), w = winPos(win);
          return this.each(function(i, el){
              var item = { el: el, show: show, hide: hide, shown: false };
              items.push(item);
              check(win, w, item);
          });
      };
      
      $(window,'body').on('scroll resize', function(e){
      	$('.lazy-img').lazyImage();
          $('.product-image').lazyImage();
		  $('.product-lazy-image').lazyImage();
		  $('.list-image').lazyImage();
		  $('.product-lazy-image1').lazyImage();

      });

      $('.body-wrapper').scroll(function(){
      	$('.product-image').lazyImage();
		  $('.product-lazy-image').lazyImage();
		  $('.list-image').lazyImage();
		  $('.lazy-img').lazyImage();
		  $('.product-lazy-image1').lazyImage();
      });

  
      
      $.fn.lazyImage = function(){
          return this.visible(function(){
            if(!$(this).hasClass('updated')) {
                $(this).addClass('updated');
                $(this).attr('src',$(this).attr('lsrc'));
                $(this).attr('src',$(this).attr('data-src'));
            }
          }, function(){
             $(this).removeClass('updated');
          });
      };

	setTimeout(function() {
    	  $('.product-image').lazyImage();
		  $('.product-lazy-image').lazyImage();
		  $('.list-image').lazyImage();
		  $('.lazy-img').lazyImage();
		  $('.product-lazy-image1').lazyImage();

	   $('body').find('.owl-next').click(function(){
      	  $('.product-image').lazyImage();
		  $('.product-lazy-image').lazyImage();
		  $('.list-image').lazyImage();
		  $('.lazy-img').lazyImage();
		  $('.product-lazy-image1').lazyImage();
      });

	},100);
	  $('.product-image').lazyImage();
	  $('.product-lazy-image').lazyImage();
	  $('.list-image').lazyImage();
	  $('.lazy-img').lazyImage();
		  $('.product-lazy-image1').lazyImage();
  

  })(jQuery);


var timeOut = null,
    searchResult = $('.search-result');
$(document).ready(function(){

	$('input[name="search"]').keyup(function() {
        var query = $(this).val();
        fetch_sesrch_result(query);
    });
	 $('.search-form').submit(function(e){
        e.preventDefault();
        var key =$('[name="search"]').val();
        if(key.length >= 3) {
             window.location = baseURL + 'search-result?key=' +$('[name="search"]').val();
        }
       
    });

    $('.submit-search').click(function(){
        var key =$('[name="search"]').val();
        if(key.length >= 3) {
             window.location = baseURL + 'search-result?key=' +$('[name="search"]').val();
        }
    });
})
   

   function search_result(result) {
    if (!result.length) {
        searchResult.addClass('d-none');
        return;
    }
    var res = '';
    $.each(result, function(i, v) {
        res += '<p data-id="' + v.ci + '" data-name="' + v.sk + '" data-type="' + v.type + '" data-url="' + v.ci + '">' + v.uv + '</p>';
    });
    searchResult.html(res);
    searchResult.removeClass('d-none');
    searchResult.find('p').click(function() {
        var tis = $(this);
        if (tis.data('type') == 'product') {
            window.location = baseURL + 'product/' + tis.data('url');
        } else if (tis.data('type') == 'category') {
            var sk = tis.data('name');
            var cat_id = tis.data('id');
            $.get(baseURL + 'ajax/get-cat-seo-url', {
                id: tis.data('id')
            }, function(res) {}, 'json').done(function(res) {
                window.location = baseURL + 'search-result?key=' + sk ;
            });
        }
    });
}

function fetch_sesrch_result(query) {
    if (query.trim() !== '') {
        clearTimeout(timeOut);
        timeOut = setTimeout(function() {
            $.get(baseURL + 'ajax/product-auto-complete', {
                q: query
            }, function() {}).done(function(result) {
                search_result(result);
            }).fail(function(e) {
                searchResult.addClass('d-none');
            });
        }, 500);
    } else {
        setTimeout(function() {
            searchResult.addClass('d-none');
        }, 600);
    }
}

$(window).on('hidden.bs.modal', function() { 
    $('.modal').find('form')[0].reset();
});


